/**
 * Main JS file for Nab
 * ===========================
 */

var nab = nab || {};
var isPageLoaded = false;

(function() {

  'use strict';


  /**
  ** Global variables
  **
  **
  **/

  nab.global = {
    $window: $(window),
    $body: $('body'),
    bodyWidth: window.innerWidth
  };





  /**
  ** Debounce resize function
  **
  **
  **/

  nab.debounce = function(func, wait, immediate) {
    var timeout;

    return function() {
      var context = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args);
      }
    };
  };

  /**
  ** Helper functions
  **
  **
  **/

  nab.helpers = {
    toggleClass: function(target, targetClass) {
      target.toggleClass(targetClass);
    },
    removeClass: function(target, targetClass) {
      target.removeClass(targetClass);
    },
    addClass: function(target, targetClass) {
      target.addClass(targetClass);
    },
    scrollTo: function(target, speed, offset) {
      let $target = $(target);
      let scrollSpeed = (speed) ? speed : 500;
      let globalOffset = (offset) ? offset : 0;
      let bodyDataOffset = nab.global.$body.data('global-offset')
      let completeCalled = false;

      if (bodyDataOffset) {
        globalOffset = bodyDataOffset;
      }

      if ($target.length) {
        $('html, body').animate({
            scrollTop: $target.offset().top - globalOffset
          },
          {
            complete: function() {
              if (!completeCalled) {
                completeCalled = true;
              }
            }
          },
          scrollSpeed);
      } else {
        console.warn('anchor: ' + target + ' does not exist to scroll to')
      }
    }
  };




  /**
  ** Scroll To function
  **
  **
  **/

  nab.scrollers = {
    init: function() {
      this.events();
    },
    events: function() {
      this.scrollTo();
    },
    scrollTo: function() {
      var self = this;

      $('.js-scrollTo').on('click', function(e) {
        e.preventDefault();
        if (!$(this).hasClass('no-link')) {      
          let element = $(this);
          let target = element.attr('href');
          let dataTarget = element.data('scrollto');
          let dataOffset = element.data('scroll-offset');

          if (dataTarget) {
            target = dataTarget;
          }

          if (!dataOffset) {
            dataOffset = 0;
          }

          //Added so that mobile users will see an expanded chart with data when clicking on certain
          //property boxes instead of seeing a collapsed accordion, to avoid confusion.
          if (target == '#median-rent') {
            $('#median-rent').addClass('accordion-is-active');
          }
          if (target == '#median-sale-prices') {
            $('#median-sale-prices').addClass('accordion-is-active');
            $('#properties-sold').addClass('accordion-is-active');
            $('#average-price').addClass('accordion-is-active');
            $('#median-rent').addClass('accordion-is-active');
          }
          //end - expand chart content

          nab.helpers.scrollTo(target, 500, dataOffset); 
        }
      });
    },
    reInitScrollTo: function() {
      var self = this;

      // THIS ISNT WORKING
      $('.js-scrollTo').off('click');
      self.scrollTo();
    }
  };


  /**
  ** Misc functions
  **
  **
  **/

  nab.misc = {
    init: function() {
      this.sliders();
      this.resize();
      this.tooltip();
    },
    sliders: function() {
      // make sure scroll positions are calulated after slick sorts out his stuff
      $('.js-slider-fullwidth').on('init', function(event, slick) {
        nab.scrollToFix.fix();
      });
      // Full width slider
      $('.js-slider-fullwidth').not('.slick-initialized').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000
      });
      // js-stats-slider


      // $('.js-stats-slider').not('.slick-initialized').slick({
      // 	dots: false,
      // 	arrows: false,
      // 	autoplay: false,
      // 	infinite: false,
      // 	slidesToShow: 4,
      // 	slidesToScroll: 1,
      // 	centerMode: false,
      // 	variableWidth: true,
      // 	//mobileFirst: true,
      // 	responsive: [
      // 		{
      // 			breakpoint: 1200,
      // 			settings: {
      // 				slidesToShow: 3
      // 			}
      // 		},
      // 		{
      // 			breakpoint: 580,
      // 			settings: {
      // 				slidesToShow: 2,
      // 				centerMode: true
      // 			}
      // 		}
      // 	]
      // });


      $('.js-stats-slider').on('destroy', function(ev, slick) {

        let carousel = $(this);

        let reinit = function() {
          /*
          * slick.activeBreakpoint will tell us the breakpoint
          * at which the carousel was destroyed.
          */
          if (slick.activeBreakpoint < window.innerWidth) {
            return;
          }

          // Re-initialize with the old settings.
          carousel.not('.slick-initialized').slick(slick.options);

          // Remove this event listener.
          window.removeEventListener('resize', reinit);
        };

        // Assign our debounced callback to window.resize.
        //window.addEventListener('resize', reinit);

        window.addEventListener('resize', nab.debounce(reinit, 250));

      });

      $('.js-stats-slider').not('.slick-initialized').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        mobileFirst: true,
        responsive: [{
            breakpoint: 1300,
            settings: 'unslick'
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });


      $('.js-stats-slider').not('.slick-initialized').slick({
        dots: true,
        arrows: true,
        autoplay: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        mobileFirst: true,
        responsive: [{
            breakpoint: 1400,
            settings: 'unslick'
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerMode: true
            }
          }
        ]
      });


      // Slider for property
      $('.js-property-slider').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $('.prop-pagination').text(i + '/' + slick.slideCount);
      });

      // Logic changes if there is one image on slider - slick slider default option does not work here

      if ( $('.js-property-slider li').length > 1) {
        $('.js-property-slider').not('.slick-initialized').slick({
          dots: false,
          autoplay: false,
          prevArrow: $('.prop-prev'),
          nextArrow: $('.prop-next')
        });
      } else if ( $('.js-property-slider li').length == 1) {
        $('.prop-slider-gallery').css({'visibility':'visible'})
        $('.prop-slide-nav').css({'visibility':'hidden'})
      }
    },
    resize: function() {
      window.addEventListener('resize', nab.debounce(function() {
        nab.global.bodyWidth = window.innerWidth
      }, 250));
    },
    tooltip: function() {
      $('.custom-tooltip').tooltip();
    }
  };

  /**
  ** Generic Accordion styles
  **
  **
  **/

  nab.accordion = {
    elements: {
      accordionActiveClass: 'accordion-is-active'
    },
    init: function() {
      var self = this;

      self.events();

      // On window resize destroy all active states
      //window.addEventListener('resize', nab.debounce(function() {
      //	self.accordionDestroyAll();
      //}, 250));
    },
    events: function() {
      var self = this;

      // Standard Toggle
      $('.js-accordion .js-accordion-trigger').on('click', function(e) {
        e.preventDefault();
        let trigger = $(this);
        let dataTarget = $(this).data('accordion-target');
        let target = trigger.closest(dataTarget);
        let $accordionParent = (dataTarget) ? target : target = $(this).parent();

        self.accordionToggle($accordionParent);
      });
    },
    accordionToggle: function(target) {
      var self = this;

      nab.helpers.toggleClass(target, self.elements.accordionActiveClass);
      nab.helpers.scrollTo(target);
    },
    accordionDestroyAll: function() {
      var self = this;

      $('.' + self.elements.accordionActiveClass).removeClass(self.elements.accordionActiveClass);
    },
    reInit: function() {
      $('.js-accordion .js-accordion-trigger').off();

      this.events();
    }
  };






  /**
  ** Priceline Dropdown Section
  **
  **
  **/

  nab.plDropdown = {
    elements: {
      activeClass: 'is-active',
      plIsOpen: false
    },
    init: function() {
      this.setup();
      this.events();
    },
    setup: function() {
      var self = this;

      // reset plIsOpen variable in case this function is reinited
      self.elements.plIsOpen = false;

      // Slider for priceline dropdowns
      $('.js-priceline-extra-slider').not('.slick-initialized').slick({
        dots: false,
        arrows: false,
        fade: true,
        swipe: false,
        touchMove: false,
        adaptiveHeight: true,
        autoplay: false
      });
    },
    events: function() {
      var self = this;

      // open accordion
      $('body').on('click', '.js-priceline-extra-trigger', function(e) {
        e.preventDefault();
        var $this = $(e.target);

        var slideToIndex = $this.data('slideindex');

        // calculate whether or not to animate slide
        var animate = (self.elements.plIsOpen) ? false : true;

        // slide to correct slide
        $('.js-priceline-extra-slider').slick('slickGoTo', slideToIndex, animate);

        // add class parent to open dropdown
        nab.helpers.addClass($('.pricelines-wrapper'), self.elements.activeClass);

        // slide to container
        nab.helpers.scrollTo($('.js-priceline-extra-slider'));

        // set plIsOpen as true
        self.elements.plIsOpen = true;
      });

      // close accordion
      $('.js-priceline-extra-close').on('click', function(e) {
        e.preventDefault();

        nab.helpers.removeClass($('.pricelines-wrapper'), self.elements.activeClass);

        // set plIsOpen as false
        self.elements.plIsOpen = false;
      });
    }
  };






  /**
  ** Tabbing section with accordion property listing
  **
  **
  **/

  nab.tabs = {
    init: function() {
      this.setup();
      this.events();
    },
    events: function() {
      let self = this;
      // navigation click
      $('.js-tab-nav a').on('click', function(e) {
        e.preventDefault();

        let $self = $(this);
        let activeClass = 'is-active';
        let tabIndex = $self.data('tab-index');

        // slide slick to correct index
        $('.js-tab-slider').slick('slickGoTo', tabIndex);

        // add active class onto nav and remove the class from siblings
        nab.helpers.addClass($self, activeClass);
        nab.helpers.removeClass($self.siblings(), activeClass);
      });

      // on accordion click, reset slick slider height (uses default accordion js method)
      /* $('.js-tab-slider .js-accordion-trigger').on('click', function() {
        $('.js-tab-slider').slick('slickSetOption', '', '', true);
      }); */

      // toggle view more and view less button
      /* $('.js-prop-load-more').on('click', function(e) {
        nab.helpers.toggleClass($(this).parents('.prop-tab-group'), 'extra-is-visible');
        $('.js-tab-slider').slick('slickSetOption', '', '', true);
      }); */

    },
    setup: function() {
      let self = this;

      $('.js-tab-slider').slick({
        dots: false,
        arrows: false,
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        fade: true,
        swipe: false,
        adaptiveHeight: true
      });
    }
  };





  /**
  ** Nav functions
  **
  **
  **/

  nab.nav = {
    elements: {
      $body: nab.global.$body,
      // main navigation elements
      $navTrigger: $('.js-nav-trigger'),
      navActiveClass: 'nav-is-active',
      // nav elements
      $subNavTrigger: $('.nav-link-sub-trigger'),
      subNavActiveClass: 'nav-link-is-active',
      // class to close nav
      $closeNavTrigger: $('.js-nav-trigger-close')
    },
    init: function() {
      this.events();
    },
    events: function() {
      let self = this;

      self.triggerNav();
      self.elements.$body.on('click', '.js-nav-trigger-close', function() {
        self.closeNav();
        self.toggleAriaAttr();
      });

    },
    triggerNav: function() {
      let self = this;

      self.elements.$body.on('click', '.js-nav-trigger', function(e) {
        e.preventDefault();
        self.toggleAriaAttr();
        nab.helpers.toggleClass(self.elements.$body, self.elements.navActiveClass);
      });

      self.elements.$subNavTrigger.on('click', function(e) {
        e.preventDefault();
        let $parent = $(this).parent();

        nab.helpers.toggleClass($parent, self.elements.subNavActiveClass);
      });
    },
    closeNav: function() {
      let self = this;
      nab.global.$body.removeClass(nab.nav.elements.navActiveClass);
    },
    toggleAriaAttr: function() {
      $('.js-nav-trigger').attr('aria-expanded', function(index, attr) {
        return attr === 'false' ? 'true' : 'false';
      });
    }
  };






  /**
  ** Search functionality
  **
  **
  **/
  nab.search = {
    init: function() {
      this.events();
    },
    events: function() {
      this.search();
    },
    search: function() {
      var self = this;

      // $('#bottomSearch').click(function(e) {
      // 	$.Event(e).preventDefault();

      // 	$.ajax({
      // 		url: 'json.php',
      // 		cache: false,
      // 		dataType: "html",
      // 		data: {
      // 			userinput: $("#data_propertyaddresssuburb").val()
      // 		},
      // 		success: function(data, textStatus, jqXHR) {
      // 			var response = JSON.parse(data);
      // 			var status = response.status;
      // 			//alert(response.estimatedvalue);
      // 			if(status === 200){
      // 				//location.href='success.php';
      // 				//location.reload();
      // 			}else {
      // 				$('#data_propertyaddresssuburb').attr('aria-invalid', 'true').addClass('error-control').parent().parent().addClass('error-parent');
      // 				$('#hint_propertyaddresssuburb').after("<div for='data_propertyaddresssuburb' id='error_propertysuburb' generated='true' class='error' style='display: block;'>Please select a valid address from suggestions.</div>");
      // 			}
      // 		},
      // 		error: function(response, status, errorThrown) {
      // 			$('#data_propertyaddresssuburb').attr('aria-invalid', 'true').addClass('error-control').parent().parent().addClass('error-parent');
      // 			$('#hint_propertyaddresssuburb').after("<div for='data_propertyaddresssuburb' id='error_propertysuburb' generated='true' class='error' style='display: block;'>Please select a valid address from suggestions.</div>");
      // 		},
      // 		type: "POST"
      // 	});
      // });


    }
  };




  /**
  ** Scroll and fix navigation
  **
  **
  **/

  nab.scrollToFix = {
    elements: {
      $scrollToFix: $('.js-scrolltofix')
    },
    init: function() {
      this.events();
    },
    events: function() {
      this.resize();
      this.detectScroll();
    },
    fix: function(target, scrollTop) {
      let self = this;
      nab.global.$window.scroll(function() {
        //Commented target.toggleClass line as it's not working on some cases and replaced
        //it with removeClass/addClass plus registered detectScroll() to make the sticky nav work.
        //target.toggleClass('is-fixed', nab.global.$window.scrollTop() > elementPosition.top);
        let startPoint = $('.header-banner').height();
        let scrollPos = nab.global.$window.scrollTop();
        let target = $('.nav-middlefixed');
        if (scrollPos > startPoint) {
          target.addClass('is-fixed');
        }
        if (scrollPos < startPoint + 1) {
          target.removeClass('is-fixed');
        }
      });
    },
    resize: function() {
      let self = this;
      window.addEventListener('resize', nab.debounce(function() {
        self.fix();
      }, 250));
    },
    detectScroll: function() {
      let self = this;
      window.addEventListener('scroll', function(e) {
        self.fix();
        resetAnchorPadding();
      });
    }
  };






  /**
  ** Scroll and fix navigation
  **
  **
  **/

  nab.validate = {
    init: function() {
      this.events();
    },
    events: function() {
      this.validate($('#signup-form'));
    },
    validate: function(target) {
      let self = this;
      let $emailField = '';
      let $phoneField = '';


      target.find('button').on('click', function(e) {
        let $emailField = target.find('input[type="email"]').val();
        let $phoneField = target.find('input[name="signup-phone"]').val();

        // if even one field is empty
        if (!self.validateEmpty()) {
          self.error(e, target, 'Please fill all fields');

          // if email field is not an email
        } else if (!self.validateEmail($emailField)) {
          self.error(e, target, 'Please enter valid email');

          // if phone field is not a phone
        } else if (!self.validatePhone($phoneField)) {
          self.error(e, target, 'Please enter numbers only for your phone number');

        } else {
          self.success(e, target);
        }
      });
    },
    validateEmpty: function() {
      var isValid = true;
      $('form input').each(function() {
        if ($(this).val() === '')
          isValid = false;
      });
      return isValid;
    },
    validateEmail: function(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validatePhone: function(phone) {
      return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(phone);
    },
    success: function(e, target) {
      target.removeClass('is-invalid');
      target.addClass('is-valid');
      $('.form-error-message').html();
      e.preventDefault();
      //window.location(target.data('url'));
    },
    error: function(e, target, msg) {
      e.preventDefault();
      target.removeClass('is-valid');
      target.addClass('is-invalid');
      $('.form-error-message').html(msg);
    }
  };



  /**
  ** Ajax request template generation
  **
  **
  **/

  nab.requests = {
    elements: {
      $searchTrigger: $('#bottomSearch'),
      $formNotification: $('#form-notification'),
      $searchInput: $('#data_propertyaddresssuburb'),
      $formSubmit: $('#signup-submit')
    },

    init: function() {
      this.events();
    },

    events: function() {
      let self = this;

      // check url for id segment, if present, run full page ajax
      //let urlId = self.urlSegment('id');
      let urlEndSegment = self.urlEndSegment();
      if (urlEndSegment) {
        self.ajaxCallFullPage(urlEndSegment);
      }

      // search input error messages
      self.elements.$searchInput.on('input', function() {
        self.clearError();
      });

      // on search button click trigger ajax call for JSON object
      self.elements.$searchTrigger.on('click', function(e) {
        e.preventDefault();

        // Triggered event when user performs a valid search for a property location
        digitalData.event.push({
            "eventType": "search-click",
            "data": {
                "eventType": "search-click",
                "keyword": "location:" + self.elements.$searchInput.val()
            }
        });
        $(document).trigger("search-click");
        self.ajaxCallFirstPage();
      });
    },

    // function to return segments of the url
    urlSegment: function(name, url) {
      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    // function to return segments of the url
    urlEndSegment: function urlEndSegment(url, urlendsegment) {
      if (!url) {
        url = window.location.href;
        //return url.match(/[^\/]*$/);
        let pattern = "/report/";
        if (url.indexOf(pattern) >= 0) {
          urlendsegment = url.substr(url.indexOf(pattern) + pattern.length, url.length);
          return urlendsegment;
        }
      }
    },

    // ajax call for first page
    ajaxCallFirstPage: function() {
      let self = this;

      let dataStream = {
        dataType: 'html',
        url: '/json.php',
        type: 'POST',
        data: {
          userinput: self.elements.$searchInput.val()
        },
        beforeSend() {
          self.onPreload();
        },
        success(resp) {
          var response = JSON.parse(resp);
          var status = response.status; // a value inside response file

          if (status === 200) {
            var addressSuburb = $('#data_propertyaddresssuburb').val();
            nab.templates.filter(response);
            self.onSuccess();
            $('#property-address-suburb').val(addressSuburb);
          } else {
            self.onError('Please select a property from the drop down list');
            // hide loader
            $('.loader-overlay').fadeOut();
          }
        },
        error() {
          self.onError('There was an error retrieving your results. Try again, or contact us on 13 78 79 for a copy of your report.');
        },
        complete() {
          self.onAfterload();
        }
      }

      isPageLoaded = false;
      $.ajax(dataStream);
      return false
    },


    // ajax call for full page
    ajaxCallFullPage: function ajaxCallFullPage(urlEndSegment) {
      let self = this;

      // get url parameters
      let propertyparam = self.urlSegment('property');
      let suburbparam = self.urlSegment('suburb');
      let id = self.urlSegment('id');
      let type = self.urlSegment('type');
      let p = urlEndSegment;

      let dataStream = {
        type: 'POST',
        dataType: 'html',
        url: '/fullreportjson.php',
        // static json below - use only for testing purposes for fullpage
        //url: 'example-full.json',
        cache: false,
        data: {
          type: type,
          id: id,
          p: p
        },
        beforeSend() {
          self.onPreload();
        },
        success(resp, textStatus, jqXHR) {
          var response = JSON.parse(resp);
          var status = response.status; // a value inside response file

          if (status === 200) {
            var addressSuburb = $('#data_propertyaddresssuburb').val();
            nab.templates.filter(response);
            self.onSuccess();
            $('#property-address-suburb').val(addressSuburb);
          } else {
            self.onError('Please select a property from the drop down list');
            // hide loader
            $('.loader-overlay').fadeOut();
          }
        },
        error(response, status, errorThrown) {
          self.onError('There was an error retrieving your results. Try again, or contact us on 13 78 79 for a copy of your report.');
        },
        complete() {
          self.onAfterload();
        }
      }

      // check to see if all parameters are present, else redirect to home

      if (!(urlEndSegment === null || urlEndSegment === '')) {
        $.ajax(dataStream);
      } else {
        // redirect to home
        window.location.href = '/home.php';
      }


    },



    onError: function(message) {
      var self = this;
      self.elements.$formNotification.html(message);
    },
    clearError: function() {
      var self = this;
      self.elements.$formNotification.empty();
    },
    onPreload: function() {
      var self = this;
      $('.loader-overlay').fadeIn();
    },
    onSuccess: function() {
      var self = this;

      // hide loader
      $('.loader-overlay').fadeOut();

      // inject into dom
      nab.templates.inject();

      // deal with after transitions
      // ============================================

      // trigger slick sliders
      nab.misc.sliders();
      nab.scrollers.reInitScrollTo();
      nab.plDropdown.init();
      nab.tabs.init();
      nab.accordion.reInit();


      // remove hidden hardcoded style on $('#dataContent')
      nab.templates.elements.$container.removeAttr('style');

      // reveal sign up form $('#signup-form')
      nab.templates.elements.$signupSection.slideDown();

      // hide content that becomes irrelevant after search $('#homeContent')
      nab.templates.elements.$preSearchContent.slideUp();

      // add completed class to $('#dataContent') to trigger css animations
      nab.templates.elements.$container.addClass('is-active');

      nab.templates.elements.$searchHeader.html('<p class="line1">A snapshot view</p><span class="line2 section-center">Use the search field below to get a snapshot of your report.</span>');

      if ($(".accordion-content").html()!=null)
        $(".accordion-content").css({"max-height" : "0"})

    },
    onAfterload: function() {
      var self = this;
      isPageLoaded = true;
      scroll_to_anchor("reportAnchor");
    }
  };


  nab.templates = {
    elements: {
      data: [],
      $container: $('#dataContent'),
      $signupSection: $('#signup-section'),
      $preSearchContent: $('#property-report'),
      $resultName: $('.js-result-name'),
      $searchHeader: $('#search-header'),
    },
    templates: {
      resultName: '',
      resultId: '',
      resultType: '',
      resultOverview: '',
      resultPropertyGallery: '',
      resultValueHistory: '',
      resultRecentHistory: '',
      resultInsights: '',
      resultPropertyPricelines: '',
      resultPropertyListings: '',
      resultGraphs: ''
    },
    charts: {
      chartMedianValue: false,
      chartMedianSalesPrice: false,
      chartNumbersSoldByPrice: false,
      chartMedianAskingRent: false,
      chartAskingPrice: false
    },
    filter: function(data) {
      var self = this;

      //push data into object elements
      self.elements.data = data;

      // start storing individual data
      self.resultSingleData(data);


      /**
      ** THIS IS WHERE YOU'LL DEFINE WHAT TEMPLATES/SECTIONS
      ** TO RENDER BASED ON JSON DATA
      **
      ** DATA.JSON === 'FIRST' - FIRST PAGE
      ** DATA.JSON === 'FULL' - FULL PAGE
      ** DATA.TYPE === 'PROPERTY' - PROPERTY RESULT
      ** DATA.TYPE === 'SUBURB' - SUBURB RESULT
      **
      ** Use conditionals to filter
      **
      **/

      // first page property result
      if (data.type === 'property' && data.json === 'first') {
        self.resultOverview();
        self.resultPropertyGallery();


        // first page suburb results
      } else if (data.type === 'suburb' && data.json === 'first') {
        self.resultOverview();
        self.templates.resultPropertyGallery = '';



        // full page property results
      } else if (data.type === 'property' && data.json === 'full') {
        self.resultOverview();
        self.resultPropertyGallery();
        self.resultPropertyPricelines();
        self.resultPropertyListings();
        self.resultInsights();
        self.resultGraphs();



        // full page suburb results
      } else if (data.type === 'suburb' && data.json === 'full') {
        
        self.resultOverview();
        self.resultPropertyListings();
        self.resultGraphs();
        self.templates.resultPropertyGallery = '';



      } else {
        console.log('json error');
      }
    },
    inject: function() {
      var self = this;

      // compile all templates into one html var
      var allHtml = `
        ${self.templates.resultOverview}
        ${self.templates.resultPropertyGallery}
        ${self.templates.resultPropertyPricelines}
        ${self.templates.resultPropertyListings}
        ${self.templates.resultInsights}
        ${self.templates.resultGraphs}
      `;

      // push allHtml into dom
      self.elements.$container.html(allHtml);

      //format resultName value to "lower case" so it can be easily formatted with...
      //...CSS to either "title case" or all uppercase where needed within the HTML
      self.templates.resultName = self.templates.resultName.toLowerCase();

      // push name where into dom where class="js-result-name" is being used
      self.elements.$resultName.html(self.templates.resultName);

      // push url into form action for signup
      //$('#signup-form').attr('action', 'results.php?type=' + self.templates.resultType + '&id=' + self.templates.resultId);
      $('#signup-form').attr('action', '/report/' + self.templates.p);
      $('#signup-p').val(self.templates.p);

      //$('#signup-form').attr('data-url',
      //	`results.php?type=${self.templates.resultType}&id=${self.templates.resultId}`);


      // render charts
      self.chartFilter();

      // add background color
      $('.graph-panel').not('.hidden').not(':odd').addClass('graph-panel--alt');

    },
    resultSingleData: function(data) {
      var self = this;

      // Name
      if (self.elements.data.nameAndNumber) {
        self.templates.resultName = self.elements.data.nameAndNumber;
      } else {
        self.templates.resultName = self.elements.data.localityname;
      }

      // ID
      self.templates.resultId = self.elements.data.id;

      // Type
      self.templates.resultType = self.elements.data.type;

      // Type
      self.templates.p = self.elements.data.p;

    },
    resultOverview: function() {
      var self = this;

      var raw_template = $('#result-overview').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultOverview = template(self.elements.data);
    },
    resultPropertyGallery: function() {
      var self = this;

      var raw_template = $('#result-property-gallery').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultPropertyGallery = template(self.elements.data);
    },
    resultPropertyPricelines: function() {
      var self = this;

      var raw_template = $('#result-property-pricelines').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultPropertyPricelines = template(self.elements.data);
    },
    resultPropertyListings: function() {
      var self = this;

      var raw_template = $('#result-property-listings').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultPropertyListings = template(self.elements.data);
    },
    resultInsights: function() {
      var self = this;

      var raw_template = $('#result-insights').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultInsights = template(self.elements.data);
    },
    resultGraphs: function() {
      var self = this;

      var raw_template = $('#result-graphs').html();
      var template = Handlebars.compile(raw_template);
      self.templates.resultGraphs = template(self.elements.data);


      // prepare graphs
      if (self.elements.data.charts.changeInMedianValueArray) {
        self.charts.chartMedianValue = true;
      }
      if (self.elements.data.charts.numbersSoldvsMediansalesprice) {
        self.charts.chartMedianSalesPrice = true;
      }
      if (self.elements.data.charts.numbersSoldByPrice) {
        self.charts.chartNumbersSoldByPrice = true;
      }
      if (self.elements.data.charts.medianAskingRentvsIndicativeGrossRentalYieldArray) {
        self.charts.chartMedianAskingRent = true;
      }
      if (self.elements.data.charts.avgDaysOnMarketvsAvgVendorDiscount) {
        self.charts.chartAskingPrice = true;
      }
      if (self.elements.data.charts.messagetext) {
        self.charts.ReportMonth = true;
      }
    },

    // Charts
    chartFilter: function() {
      var self = this;

      let globalFontFamily = 'CorpidHeavy,Arial,Helvetica,sans-serif'

      // set up chart.js defaults
      Chart.defaults.global.tooltips.titleFontStyle = globalFontFamily;
      Chart.defaults.global.tooltips.bodyFontFamily = globalFontFamily;
      Chart.defaults.global.title.fontFamily = globalFontFamily;
      Chart.defaults.scale.scaleLabel.fontFamily = globalFontFamily;
      Chart.defaults.global.tooltips.yPadding = 18;
      Chart.defaults.global.tooltips.xPadding = 12;



      if (self.charts.chartMedianValue) {
        self.chartMedianValue();
      };

      if (self.charts.chartMedianSalesPrice) {
        self.chartMedianSalesPrice();
      };

      if (self.charts.chartNumbersSoldByPrice) {
        self.chartNumbersSoldByPrice();
      };


      if (self.charts.chartAskingPrice) {
        self.chartAskingPrice();
      };

      if (self.charts.chartMedianAskingRent) {
        self.chartMedianAskingRent();
      };

      if (self.charts.ReportMonth) {
        self.ReportMonth();
      };
    },
    chartMedianValue: function() {
      let self = this;
      let data = self.elements.data.charts.changeInMedianValueArray;
      let chart = document.getElementById('chart-median-value-change').getContext('2d');
      let chartLabels = [];
      let chartData = [];


      // get chart labels from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartLabels.push(data[i].date);
      }

      // get chart data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartData.push(data[i].change);
      }

      let barChart = new Chart(chart, {
        scaleShowVerticalLines: false,
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            label: false,
            data: chartData,
            backgroundColor: "#d20000",
          }]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Change (%)'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Report Period'
              },
              gridLines: {
                display: false
              },
              barThickness: 15
            }]
          },
          tooltips: {
            callbacks: {
              // title: function(tooltipItem) {
              //      		return "Median Value";
              //      	},
              label: function(tooltipItem) {
                return tooltipItem.yLabel;
              }
            }
          }
        }
      });
    },
    chartMedianSalesPrice: function() {
      let self = this;
      let data = self.elements.data.charts.numbersSoldvsMediansalesprice;
      let chart = document.getElementById("chart-median-sales-price").getContext('2d');
      let chartLabels = [];
      let chartMedianSalesData = [];
      let chartNumberSoldData = [];
      let validMediaSalesData = false,
        validChartNumberSoldData = false;
      const $chartSoldPrice = $('#chart-median-sales-price').closest('.graph-panel'),
        $dataPoint = $('.anchor-median-sale-price'),
        $anchorDataPoint = $('.anchor-median-sale-price .btn-chevron'),
        $dataPointLink = $('.anchor-median-sale-price a'),
        clsNoLink = 'no-link',
        clsInvisible = 'hidden';

      // get chart labels from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartLabels.push(data[i].date);
      }

      // get Median Sales data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartMedianSalesData.push(data[i].mediansalesprice);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        if (data[i].mediansalesprice !== undefined) {
          validMediaSalesData = true;
          break;
        }
      }

      // get number sold data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartNumberSoldData.push(data[i].numberSold);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        if (data[i].numberSold !== undefined) {
          validChartNumberSoldData = true;
          break;
        }
      }

      if (!(validMediaSalesData && validChartNumberSoldData))  {
        // both data invalid
        $chartSoldPrice.addClass(clsInvisible);
        $dataPoint.addClass(clsNoLink);
        $anchorDataPoint.addClass(clsInvisible);
        $dataPointLink.addClass(clsNoLink);
      }

      let lineChart = new Chart(chart, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            type: 'line',
            fill: false,
            label: 'Median sale price',
            data: chartMedianSalesData,
            yAxisID: 'y-axis-1',
            lineTension: 0,
            borderWidth: 1,
            borderColor: '#000',
            pointBackgroundColor: '#000'

          }, {
            type: 'bar',
            label: 'Houses sold',
            fill: false,
            data: chartNumberSoldData,
            yAxisID: 'y-axis-0',
            backgroundColor: '#d20000'
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var value = '';
                if (tooltipItem.datasetIndex === 0) {
                  value = data.datasets[0].data[tooltipItem.index];
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(',');
                  value = '$' + value;
                } else {
                  value = data.datasets[1].data[tooltipItem.index];
                  value = 'Number sold: ' + value;
                }
                return value;
              }
            }
          },
          scales: {
            yAxes: [{
              position: 'left',
              'id': 'y-axis-0',
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: 'Number sold'
              }
            }, {
              position: 'right',
              'id': 'y-axis-1',
              scaleLabel: {
                display: true,
                labelString: 'Median sale price'
              },
              ticks: {
                callback: function(label, index, labels) {
                  if (label > 999999) {
                    return '$' + truncate(label * 0.000001, 2) + 'M';
                  } else {
                    return '$' + label / 1000 + 'k';
                  }
                }
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                //labelString: 'Report Month',
                fontFamily: 'CorpidHeavy,Arial,Helvetica,sans-serif',
                fontSize: 14,
              },
              gridLines: {
                display: false
              },
              barThickness: 15
            }],

          },
        }
      });
    },
    chartNumbersSoldByPrice: function() {
      let self = this;
      let data = self.elements.data.charts.numbersSoldByPrice;
      let chart = document.getElementById("chart-sold-price").getContext('2d');

      let validNumbersSoldByPrice = false;
      const $chartNumbersSoldByPrice = $('#chart-sold-price').closest('.graph-panel'),
        clsInvisible = 'hidden';
      
      let chartLabels = Object.keys(data);
      let chartData = Object.keys(data).map(function(itm) {        
        return data[itm];
      });

      // Check whether at least one valid data
      for (let i = 0; i < chartData.length; i++) { 
        if (chartData[i] !== '') {
          validNumbersSoldByPrice = true;
          break;
        }
      }

      if (!validNumbersSoldByPrice) {
        $chartNumbersSoldByPrice.addClass(clsInvisible);
      }

      let barChart = new Chart(chart, {
        scaleShowVerticalLines: false,
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            label: false,
            data: chartData,
            backgroundColor: "#d20000",
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var value = data.datasets[0].data[tooltipItem.index];
                value = value.toString();
                value = 'Number sold: ' + value;
                return value;
              }
            }
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Number sold'
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Value range'
              },
              gridLines: {
                display: false
              },
              barThickness: 15,
              ticks: {
                callback: function(label, index, labels) {
                  switch (label) {
                    case "morethan2M":
                      return "$2M+";
                    case "oneMto2M":
                      return "$1M - $2M";
                    case "twoktofourk":
                      return "$200k - $400k";
                    case "fourktosixk":
                      return "$400k - $600k";
                    case "sixktoeightk":
                      return "$600k - $800k";
                    case "eightkto1M":
                      return "$800k - $1M";
                    default:
                      return label;
                  }
                }
              }
            }]
          }
        }
      });
    },
    chartAskingPrice: function() {
      let self = this;
      let data = self.elements.data.charts.avgDaysOnMarketvsAvgVendorDiscount;
      let chart = document.getElementById("chart-vendor-discount").getContext('2d');
      let chartLabels = [];
      let chartVendorDiscount = [];
      let chartAverageDays = [];
      let validVendorDiscount = false,
        validAverageDays = false;
      const $chartVendorDiscount = $('#chart-vendor-discount').closest('.graph-panel'),
        clsInvisible = 'hidden';

      // get chart labels from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartLabels.push(data[i].date);
      }

      // get vendor discount data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartVendorDiscount.push(data[i].vendorDiscount);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        if (data[i].vendorDiscount !== undefined) {
          validVendorDiscount= true;
          break;
        }
      }

      // get average days data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartAverageDays.push(data[i].averageDays);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        if (data[i].averageDays !== undefined) {
          validAverageDays= true;
          break;
        }
      }

      if (!(validVendorDiscount && validAverageDays))  {
        // both data invalid
        $chartVendorDiscount.addClass(clsInvisible);
      }

      let lineChart = new Chart(chart, {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: [{
            type: 'line',
            label: 'Avg. Vendor Discount',
            fill: false,
            data: chartVendorDiscount,
            yAxisID: 'y-axis-0',
            lineTension: 0,
            borderWidth: 1,
            borderColor: '#000',
            pointBackgroundColor: '#000'
          }, {
            type: 'bar',
            fill: false,
            label: 'Avg. Days on Market',
            data: chartAverageDays,
            yAxisID: 'y-axis-1',
            backgroundColor: '#d20000'
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var value = '';
                if (tooltipItem.datasetIndex === 0) {
                  value = data.datasets[0].data[tooltipItem.index];
                  value = value.toString();
                  value = 'Avg. Vendor Discount: ' + value + '%';
                } else {
                  value = data.datasets[1].data[tooltipItem.index];
                  value = value.toString();
                  value = 'Avg. Days on Market: ' + value;
                }
                return value;
              }
            }
          },
          scales: {
            yAxes: [{
              position: 'left',
              'id': 'y-axis-1',
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: 'Avg. Days on Market'
              }
            }, {
              position: 'right',
              'id': 'y-axis-0',
              scaleLabel: {
                display: true,
                labelString: 'Avg. Vendor Discount'
              },
              ticks: {
                callback: function(label, index, labels) {
                  return Math.round(label * 10) / 10 + '%';
                }
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                //labelString: 'Report Month',
                fontFamily: 'CorpidHeavy,Arial,Helvetica,sans-serif',
                fontSize: 14,
              },
              gridLines: {
                display: false
              },
              barThickness: 15
            }],

          },
        }
      });
    },
    chartMedianAskingRent: function() {
      let self = this;
      let data = self.elements.data.charts.medianAskingRentvsIndicativeGrossRentalYieldArray;
      let chart = document.getElementById("chart-median-rent").getContext('2d');
      let chartLabels = [];
      let chartAskingRentData = [];
      let chartRentalYieldData = [];

      let validAskingRentData = false, 
        validRentalYieldData = false;
      const $chartMedianAskingRent = $('#chart-median-rent').closest('.graph-panel'),
        $dataPoint = $('.anchor-median-rent'),
        $anchorDataPoint = $('.anchor-median-rent .btn-chevron'),
        $dataPointLink = $('.anchor-median-rent a'),
        clsNoLink = 'no-link',
        clsInvisible = 'hidden';


      // get chart labels from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartLabels.push(data[i].date);
      }

      // get Median Sales data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartAskingRentData.push(data[i].medianAskingRent);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        if (data[i].medianAskingRent !== undefined) {
          validAskingRentData = true;
          break;
        }
      }

      // get number sold data from data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        chartRentalYieldData.push(data[i].yield);
      }

      // Check whether at least one valid data
      for (let i = 0; i < data.length; i++) {
        let obj = data[i];
        if (data[i].yield !== undefined) {
          validRentalYieldData = true;
          break;
        }
      }

      if (!(validAskingRentData && validRentalYieldData))  {
        // both data invalid
        $chartMedianAskingRent.addClass(clsInvisible);
        $dataPoint.addClass(clsNoLink);
        $anchorDataPoint.addClass(clsInvisible);
        $dataPointLink.addClass(clsNoLink);
      }

      let newTick, oldTick;

      let lineChart = new Chart(chart, {
        type: 'line',
        data: {
          labels: chartLabels,
          datasets: [{
            //type: 'line',
            fill: false,
            label: 'Median Asking Rent',
            data: chartAskingRentData,
            yAxisID: 'y-axis-1',
            lineTension: 0,
            borderWidth: 1,
            borderColor: '#000',
            pointBackgroundColor: '#000'

          }, {
            //type: 'line',
            label: 'Indicative Gross Rental Yield',
            fill: false,
            data: chartRentalYieldData,
            yAxisID: 'y-axis-0',
            lineTension: 0,
            borderWidth: 1,
            borderColor: '#d20000',
            pointBackgroundColor: '#d20000'
          }]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var value = '';
                if (tooltipItem.datasetIndex === 0) {
                  value = data.datasets[0].data[tooltipItem.index];
                  value = value.toString();
                  value = 'Median Asking Rent: $' + value;
                } else {
                  value = data.datasets[1].data[tooltipItem.index];
                  value = value.toString();
                  value = 'Indicative Gross Rental Yield: ' + value + '%';
                }
                return value;
              }
            }
          },
          scales: {
            yAxes: [{
              position: 'right',
              'id': 'y-axis-0',
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: 'Indicative Gross Rental Yield'
              },
              ticks: {
                callback: function(label, index, labels) {
                  newTick = Math.round(label * 10) / 10 + '%';
                  if (oldTick != newTick) {
                    oldTick = newTick;
                    return newTick;
                  }
                }
              }
            }, {
              position: 'left',
              'id': 'y-axis-1',
              scaleLabel: {
                display: true,
                labelString: 'Median Asking Rent'
              },
              ticks: {
                callback: function(label, index, labels) {
                  return '$' + label;
                }
              }
            }],
            xAxes: [{
              scaleLabel: {
                display: true,
                //labelString: 'Report Month',
                fontFamily: 'CorpidHeavy,Arial,Helvetica,sans-serif',
                fontSize: 14,
              },
              gridLines: {
                display: false
              },
              barThickness: 15
            }],

          },
        }
      });
    },
    ReportMonth: function() {
      let self = this;
      let msg = self.elements.data.charts.messagetext;
      $(".reportMonthMsg").html(msg + ".");
    }
  };







  /**
  * Initialize
  * @return {[]} []
  */
  nab.init = function() {
    nab.nav.init();
    nab.scrollToFix.init();
    nab.accordion.init();
    nab.scrollers.init();
    nab.misc.init();
    nab.plDropdown.init();
    nab.tabs.init();
    nab.requests.init();
    //nab.validate.init();
  };

  nab.init();

}());

// When the captcha is checked/resolved, it triggers this function which checks the hidden/required checkbox.
function recaptchaCallback() {
  
  // Fix reCaptcha jumps to page end in iOS
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    var formTop = 0,
        $recaptchaForm = $('#signup-form');
    if ($recaptchaForm.length) {
      formTop = $recaptchaForm.offset().top;
      $(window).scrollTop(formTop);
    }
  }

  $("#recaptcha-checkbox").prop("checked", true);
  $("#parsley-id-multiple-recaptcha-checkbox").css("display", "none");
};

// Remove decimals from properties' prices
function truncate(number, places) {
  var shift = Math.pow(10, places);
  return ((number * shift) | 0) / shift;
};

//Scroll to top of report
function scroll_to_anchor(anchor_id) {
  var $anchor = $('#' + anchor_id);
  if ($anchor.length) {
    $anchor.addClass("reportAnchorPadding");
    $('html, body').animate({
      scrollTop: $anchor.offset().top
    }, 'slow');
  } else {
    console.warn('anchor: ' + '#' + anchor_id + ' does not exist to scroll to')
  }
}

//Remove padding from anchor when user scrolls to the top
function resetAnchorPadding() {
  let $anchor = $("#reportAnchor");
  let hidePoint = $('.header-banner').height();
  if ($anchor.length) {
    if (window.pageYOffset < hidePoint + 200) {
      $anchor.removeClass("reportAnchorPadding");
    }
  }
}

$("#data_propertyaddresssuburb").keyup(function(event) {
  if (event.keyCode == 13) {
    $("#bottomSearch").click();
  }
});

$(".close-btn-ok,.close").click(function(e) {
    e.preventDefault();
    $(this).closest('.overlay').fadeOut();
})

window.onbeforeprint = function(){
  $(window).scrollTop(0);
};

// Show/hide form elements based on whether user wants Broker call back. 
$('#request-callback').change(function (e){
  if (this.checked) {
    $('#form-additional-info').find('input, select').attr('data-parsley-required', 'true');
    $('#form-additional-info').slideDown();
  } else {
    $('#form-additional-info').find('input, select').attr('data-parsley-required', 'false');
    $('#form-additional-info').slideUp();
  }
}); 

// Property type drop down control
$(document).on('change', '#sale-sold-rent', function (e){
  var selectedType = $(this).children("option:selected").val();
  
  $('.prop-tab-group.property-type-active').removeClass('property-type-active');

  $('.prop-tab-group[data-property-type='+selectedType+']').addClass('property-type-active');
}); 

// toggle view more and view less button
$(document).on('click', '.js-prop-load-more', function(e) {
  nab.helpers.toggleClass($(this).parents('.prop-tab-group'), 'extra-is-visible');
  //$('.js-tab-slider').slick('slickSetOption', '', '', true);
});

//Switch buttons out depending on page
$(document).ready(function() {
  if (window.location.href.indexOf("report") > -1) {
    $('#bottomSearch').css("display", "none");
    $('#fullSearch').css("display", "inline-block");
  }
});